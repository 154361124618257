/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

// jQuery
import $ from 'jquery';
import './modernizr-custom.js';
import 'jquery-match-height';
import Swiper from 'swiper';

// ResponsiveBP
import 'responsive-bp/src/js/responsive.core.js';

// Lightgallery
async function loadLightGallery() {
	await import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js');
	await import(/* webpackChunkName: "lightgallery" */ 'lg-video.js');

	lightGallery(document.getElementsByTagName("body")[0], {
		selector: '[rel=lightbox]',
		download: false,
		videoMaxWidth: '1920px'
	});
}

var elementsWithModal = document.querySelectorAll('[rel=lightbox]');
if (elementsWithModal.length) {
	loadLightGallery();
}

$(async function () {
	// Responsive menu
	$('.toggler').each(function () {
		$(this).click(toggleMenu);
	});

	function toggleMenu() {
		var toggler = $('.toggler');
		var responsiveNav = $('.responsive-nav');

		$(document.body).toggleClass('menu-open');
		responsiveNav.toggleClass('-collapsed');
		toggler.toggleClass('is-active');
	}

	if ("ontouchstart" in document.documentElement) {
		// Menu open
		var menuItems = $('.navigation .sub');
		var languageSelectors = $('.languages');

		menuItems.each(function () {
			var menuItem = $(this);
			var menuLink = menuItem.find('> a');

			menuLink.on('click', function (event) {
				if (!menuItem.hasClass('open')) {
					event.preventDefault();
					menuItem.addClass('open');
					return false;
				}
				else {
					event.stopPropagation();
					menuItem.removeClass('open');
				}

			});

			menuItem.find('li a').on('click', function (event) {
				event.stopPropagation();
			});
		});

		languageSelectors.each(function () {

			$(this).on('click', function (event) {
				event.preventDefault();
				$(this).addClass('open');
				return false;
			});

			$(this).find('li a').on('click', function (event) {
				event.stopPropagation();
			});
		});

		$('body').on('click', function () {
			menuItems.removeClass('open');
			languageSelectors.removeClass('open');
		});
	}

	// Tabs
	var tabs = $('.tabs');
	if (tabs.length) {
		await import("../../node_modules/responsive-bp/src/js/responsive.tabs");
	}

	// Match agenda slider item height
	$('.same-height').matchHeight({
		property: 'height'
	});

	// Slider
	$('.slider').each(async function () {
		var slider = $(this);
		const Swiper = (await import('swiper/dist/js/swiper.js')).default;
		if (slider.find('.swiper-slide').length > 1) {
			new Swiper(slider.find('.swiper-container'), {
				grabCursor: true,
				spaceBetween: 48,
				effect: slider.hasClass('slider-history') ? "slide" : "fade",
				speed: 1000,
				keyboard: true,
				navigation: {
					nextEl: slider.find('.swiper-button-next'),
					prevEl: slider.find('.swiper-button-prev')
				},
				observer: true,
				observeParents: true
			});
		}
	});

	// Masonry
	$('.masonry').each(async function () {
		const Masonry = (await import(/* webpackChunkName: "masonry-layout" */ 'masonry-layout')).default;

		new Masonry(this, {
			percentPosition: true
		});
	});

	// Dropdown
	$(".accordion").each(async function () {
		await import("../../node_modules/responsive-bp/src/js/responsive.dropdown.js");

		// Fix for link in the dropdown part
		$(this).find('a').on('click', function (event) { event.stopPropagation(); })
	});

	// FAQ 
	var dropdowns = $('.accordion');
	if (dropdowns.length) {
		await import("../../node_modules/responsive-bp/src/js/responsive.dropdown.js");
	}

	// Object fit fallback
	if (!Modernizr.objectfit) {
		$('picture.object-fit').each(function () {
			var element = $(this);
			var image = element.find('img');
			var parent = element.parent();

			parent.css({ 'background-size': 'cover', 'background-image': 'url(' + image.attr('src') + ')', 'background-position': 'center center' });
			element.remove();
		});
	}

	// Smooth scroll
	$('a[href*=\\#]').click(function () {
		var target = $(this.hash);

		if (!target.length) {
			target = $('[name=' + this.hash.slice(1) + ']');
		}

		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 250);

			return false;
		}
	});

	// file uploades
	var inputs = document.querySelectorAll('.fileupload input');
	Array.prototype.forEach.call(inputs, function (input) {
		var label = input.nextElementSibling;

		input.addEventListener('change', function (e) {
			var fileName = '';
			if (this.files && this.files.length > 1)
				fileName = (this.getAttribute('data-multiple-caption') || '').replace('{0}', this.files.length);
			else
				fileName = e.target.value.split('\\').pop();

			console.log(label);

			if (fileName)
				label.innerHTML = fileName;
		});
	});

	// Instagram slider
	var instaScrollbar = document.getElementById("swiper-scroll");
	var instaSwiper = document.querySelectorAll('.instagram-feed .swiper-container');
	if (instaSwiper.length) {
		$(function () {
			var swiper = new Swiper(".instagram-feed .swiper-container", {
				slidesPerView: '1',
				watchOverflow: true,
				scrollbar: {
					el: instaScrollbar,
					hide: false,
					dragSize: 20
				},
			});
		})
	}
});